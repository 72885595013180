.footer {
    background-color: #1a1a1a;
    color: #ffffff;
    font-size: 0.9rem;
    width: 100%;
    flex-shrink: 0;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1.5rem 2rem;
    box-sizing: border-box;
  }
  
  .footer-left {
    display: flex;
    flex-direction: column;
  }
  
  .logo {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .logo-icon {
    color: #00ff88;
    margin-right: 0.5rem;
  }
  
  .company-name {
    font-size: 1.2rem;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  
  .copyright {
    color: #888888;
    margin: 0;
  }
  
  .contact-link {
    color: #00ff88;
    text-decoration: none;
    transition: color 0.2s ease;
  }
  
  .contact-link:hover {
    color: #00cc00;
    text-decoration: underline;
  }
  
  @media (max-width: 600px) {
    .footer-content {
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem;
    }
    
    .footer-right {
      margin-top: 1rem;
    }
  }