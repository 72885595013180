/* HeroSection.css */
.hero-section {
  color: #1a1a1a;
  padding: 4rem 0;
  overflow: hidden;
}

.hero-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.hero-text {
  flex: 1;
  padding-right: 2rem;
}

.hero-subtitle {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 1rem;
  text-align: left;
}

.highlightHero {
  color: white;
  background: #00ff88;
  padding-left: 12px;
  padding-right: 12px;
}

.hero-images {
  flex: 1;
  position: relative;
  height: 500px;
  width: 100%;
}

.hero-images img {
  position: absolute;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  max-width: 60%;
}

.image-2 {
  top: 5%;
  left: -20px;
  z-index: 1;
}

.image-1 {
  top: 42%;
  right: 10%;
  z-index: 2;
}

.image-3 {
  top: 10%;
  z-index: 1;
  right: -1%;
}

@media (max-width: 768px) {
  .hero-section {
    padding: 2rem 0; /* Reduced padding for mobile */
  }

  .hero-content {
    flex-direction: column;
    padding: 0 1rem; /* Reduced side padding for mobile */
  }

  .hero-text {
    padding-right: 0;
    margin-bottom: 1rem; /* Reduced margin for mobile */
  }

  .hero-subtitle {
    font-size: 2rem; /* Reduced font size for mobile */
  }

  .hero-images {
    display: none; /* Hide images on mobile */
  }
}