.header {
    position: fixed;
    width: 100%;
    background-color: #1a1a1a;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem 2rem;
  }
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo-icon {
    color: #00ff88;
    margin-right: 0.75rem;
  }
  
  .company-name {
    font-size: 1.5rem;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  
  .contact-button {
    background-color: #00ff88;
    color: #1a1a1a;
    text-decoration: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    font-weight: 600;
    font-size: 0.9rem;
    transition: all 0.2s ease;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
  
  .contact-button:hover {
    background-color: #00cc00;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 255, 0, 0.2);
  }
  
  @media (max-width: 600px) {
    .header-content {
      padding: 0.75rem 1rem;
    }
    
    .company-name {
      font-size: 1.2rem;
    }
    
    .contact-button {
      padding: 0.5rem 1rem;
      font-size: 0.8rem;
    }
  }