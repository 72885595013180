.contextual-awareness-sectionContext {
  position: relative;
  color: #1a1a1a;
  padding: 4rem 0;
  overflow: hidden;
  background: #fff;
}

.contentContext {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.image-contentContext {
  flex: 0 0 50%;
  position: relative;
  height: 700px;
  margin-right: 5rem;
}

.imageContext {
  position: absolute;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  height: auto;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
}

.imageContext:hover {
  transform: scale(1.1);
  z-index: 10;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.image-1Context { top: 0; left: 0; z-index: 1; width: 60%; }
.image-2Context { top: 5%; right: 0; z-index: 2; width: 60%; }
.image-3Context { bottom: 5%; left: 10%; z-index: 3; width: 80%; }

.text-contentContext {
  flex: 1;
  position: relative;
  text-align: left;
  margin-top: 36px;
}

.titleContext {
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 2.5rem;
}

.highlightContext {
  color: white;
  background: #00ff88;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: bold;
}

.subtitle-containerContext {
  display: flex;
  align-items: flex-start;
  margin-bottom: 3rem;
}

.iconContext {
  flex-shrink: 0;
  margin-right: 1rem;
  margin-top: 0.2rem;
}

.subtitleContext {
  font-size: 0.9rem;
  line-height: 1.6;
  color: rgba(74, 74, 74, 0.8);
  margin: 0px;
}

.subtitleContext strong {
  color: #1a1a1a;
  font-weight: 600;
}

@media (max-width: 1024px) {
  .contextual-awareness-sectionContext {
    padding: 3rem 0;
  }

  .contentContext {
    flex-direction: column;
    padding: 0 1.5rem;
  }

  .image-contentContext {
    display: none; /* Hide images for tablet and mobile */
  }

  .text-contentContext {
    width: 100%;
    margin-top: 0; /* Remove top margin as images are hidden */
  }

  .titleContext {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .subtitle-containerContext {
    margin-bottom: 2rem;
  }
}

@media (max-width: 768px) {
  .contextual-awareness-sectionContext {
    padding: 2rem 0;
  }

  .contentContext {
    padding: 0 1rem;
  }

  .titleContext {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }

  .subtitleContext {
    font-size: 0.8rem;
  }

  .subtitle-containerContext {
    margin-bottom: 1.5rem;
  }

  .iconContext {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 480px) {
  .contextual-awareness-sectionContext {
    padding: 1.5rem 0;
  }

  .contentContext {
    padding: 0 0.75rem;
  }

  .titleContext {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .subtitleContext {
    font-size: 0.75rem;
  }

  .subtitle-containerContext {
    margin-bottom: 1rem;
  }
}